import React from 'react'
import Head from '../pages/components/head'
import Layout from '../pages/components/layout'

const Success = props => (
    
    <Layout>
        <Head title="Success Page"/>
        <meta name="description" content="Success page" />

        <div id="main">
            <h1>Success</h1>
            <p>Thank you for contacting me!</p>
        </div>
    </Layout>
)

export default Success
